import mqtt from 'mqtt'
import { ref } from "vue";
import { getMqtt, getMqttConnectInfor } from "@/constant";
import CryptoJS from "crypto-js";


// 声明mqtt的options数据包类型

// 为方便外部组件处理message,声明消息回调方法类型
export declare type OnMessageFun = (topic: string, payload: Buffer) => void

// 声明mqtt主题配置
interface Topic {
    topic: string
    qos: 0 | 1 | 2
}
//设置mqtt连接状态
const mqttStatu = ref<boolean>(false)
const reconnectNum = ref<number>(0)
export class Mqtt {
    mqclient!: mqtt.MqttClient //mq连接实例
    brokerHost: string //连接地址
    // brokerPort: number //这里可以自定义端口,看自己怎么定义
    subscribeTopics: Array<Topic> // 订阅的主题数组
    subscribeCallbacks: Map<string, OnMessageFun> //订阅消息的回调
    constructor(host?: string | any, port?: number | any) {
        this.brokerHost = host
        // this.brokerPort = port
        this.subscribeTopics = new Array<Topic>()
        this.subscribeCallbacks = new Map<string, OnMessageFun>()
    }
    /**
     * @description: 订阅主题
     * @param {*}
     * @return {*}
     */
    public subscribe(topic: string, qos: 0 | 1 | 2): void {
        this.subscribeTopics.push({ topic, qos })
        if (this.is_connect()) {
            this.mqclient.subscribe(topic, { qos: qos }, error => {
                if (!error) {
                    console.log('subscribe-success')
                    mqttStatu.value = true
                } else {
                    console.log('subscribe-fail')
                }
            })
        }
    }
    // //取消订阅
    // public cancleContract(topic: string, qos: 0 | 1 | 2) {
    //     this.subscribeTopics.push({ topic, qos })
    //     if (this.is_connect()) {
    //         this.mqclient.unsubscribe(topic, { qos: qos }, error => {
    //             if (!error) {
    //                 console.log('取消订阅-success')
    //             } else {
    //                 console.log('取消订阅-fail')
    //             }
    //         })
    //     }
    // }
    /**
     * @description: 判断是否已经连接mq
     * @param {*}
     * @return {*}
     */
    public is_connect(): boolean {

        return this.mqclient.connected === true
    }
    /**
     * 关闭该mqtt
     * @returns 
     */
    public end() {
        this.mqclient.end()
    }

    /**
     * @description: 消息数据回调方法
     * @param {*}
     * @return {*}
     */

    public message_callback(topicPatten: string, cb: OnMessageFun) {
        this.subscribeCallbacks.set(topicPatten, cb)
    }

    /**
     * @description: 连接方法
     * @param {MqttconnecOption} options:mqtt连接参数体
     * @return {*}
     */
    public connect(options: mqtt.IClientOptions) {
        const parmas = options
        this.mqclient = mqtt.connect(this.brokerHost, parmas)
        // 连接
        this.mqclient.on('connect', () => {
            for (let i = 0; i < this.subscribeTopics.length; i++) {
                const el = this.subscribeTopics[i]
                this.mqclient.subscribe(el.topic, { qos: el.qos }, error => {
                    if (!error) {
                        console.log('connect-success')
                        reconnectNum.value = 0
                        mqttStatu.value = true
                    } else {
                        console.log('connect-fail')
                        mqttStatu.value = false

                    }
                })
            }
        })
        // 消息
        this.mqclient.on('message', (topic: string, payload: Buffer) => {
            this.mqclient
            this.subscribeCallbacks.forEach((val, key) => {
                if (topic.indexOf(key) !== -1) {
                    val(topic, payload)
                }
            })
        })
        // 重连
        this.mqclient.on('reconnect', () => {
            // mqttStatu.value = true
            reconnectNum.value++
            // if (reconnectNum.value >= 10) {
            //     getMqttConnectInfor()
            //     parmas.clientId = getMqtt().token + "|" + "signmethod=hmacmd5" + ",timestamp=" + getMqtt().timestamp
            //     parmas.username= getMqtt().username,
            //     parmas.password=CryptoJS.HmacMD5(parmas.clientId, getMqtt().secretKey).toString()
            //     this.mqclient = mqtt.connect(this.brokerHost, parmas)
            // }
            console.log(`第${reconnectNum.value}次` + 'reconnect')
        })

        // 错误
        this.mqclient.on('error', (err: Error) => {
            console.log(err, '错误信息')
            mqttStatu.value = false
        })
        // 离线
        this.mqclient.on('offline', (err: Error) => {
            console.log('离线')
            mqttStatu.value = false
        })
    }
}
export {
    mqttStatu,
    reconnectNum
}
