import { get, put, post, del, page } from "@/api/service";
import * as bo from "./index.type.bo";
import * as vo from "./index.type.vo";

const baseUrl = "djmes/messerver";


export default {
  /**
   * 获取调整原因可选列表-/machine/production/getAdjustmentReasonList
   * @returns 
   */
  postMachineProductionGetAdjustmentReasonList() {
    return post(baseUrl + "/machine/production/getAdjustmentReasonList");
  },
  /**
   * 计数上报-/machine/production/countReport
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionCountReport(bo: bo.IPostMachineProductionCountReportBo) {
    return post(baseUrl + "/machine/production/countReport", bo);
  },
  /**
   * 机台屏-工单信息-根据工单二级配置id获取配置字段列表-/machine/production/getConfigurableFieldBySonId
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionGetConfigurableFieldBySonId(bo: bo.IPostMachineProductionGetConfigurableFieldBySonIdBo) {
    return post<page<vo.IPostMachineProductionGetConfigurableFieldBySonIdVo>>(baseUrl + "/machine/production/getConfigurableFieldBySonId", bo);
  },
  /**
   * 机台屏-工单信息-获取工单配置字段和内容，当前生产订单-/machine/production/getCurWorkOrderInfo
   * @returns 
   */
  postMachineProductionGetCurWorkOrderInfo() {
    return post<page<vo.IPostMachineProductionGetCurWorkOrderInfoVo>>(baseUrl + "/machine/production/getCurWorkOrderInfo");
  },
  /**
   * 获取mqtt连接信息-/machine/production/getMqttConnectInfo
   * @returns 
   */
  getMachineProductionGetMqttConnectInfo() {
    return get<vo.IGetMachineProductionGetMqttConnectInfoVo>(baseUrl + "/machine/production/getMqttConnectInfo");
  },
  /**
   * 获取工序接点列表-/machine/production/getProcedureOperationList
   * @returns 
   */
  postMachineProductionGetProcedureOperationList() {
    return post<vo.IPostMachineProductionGetProcedureOperationListVo>(baseUrl + "/machine/production/getProcedureOperationList");
  },
  /**
   * 机台操作-/machine/production/machineOperation
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionMachineOperation(bo: bo.IPostMachineProductionMachineOperationBo) {
    return post(baseUrl + "/machine/production/machineOperation", bo);
  },
  /**
   * 机台屏-工单信息-提交字段排序及是否展示-/machine/production/setFieldShow
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionSetFieldShow(bo: bo.IPostMachineProductionSetFieldShowBo) {
    return post(baseUrl + "/machine/production/setFieldShow", bo);
  },
  /**
   * 获取当前生产的工单机台班组信息-/machine/production/getCurMachineTeamInfo
   * @returns 
   */
  postMachineProductionGetCurMachineTeamInfo() {
    return post<vo.IPostMachineProductionGetCurMachineTeamInfoVo>(baseUrl + "/machine/production/getCurMachineTeamInfo");
  },
  /**
   * 获取当前生产的工单生产计数信息-/machine/production/getCurProducingProductionCountInfo
   * @returns 
   */
  postMachineProductionGetCurProducingProductionCountInfo() {
    return post<vo.IPostMachineProductionGetCurProducingProductionCountInfoVo>(baseUrl + "/machine/production/getCurProducingProductionCountInfo");
  },
  /**
   * 获取当前生产的工单生产信息-/machine/production/getCurProductionWorkOrderList
   * @returns 
   */
  postMachineProductionGetCurProductionWorkOrderList() {
    return post<vo.IPostMachineProductionGetCurProductionWorkOrderListVo>(baseUrl + "/machine/production/getCurProductionWorkOrderList");
  },
  /**
   * 获取指定工单机台班组信息-/machine/production/getMachineTeamInfoById
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionGetMachineTeamInfoById(bo: bo.IPostMachineProductionGetMachineTeamInfoByIdBo) {
    return post<vo.IPostMachineProductionGetMachineTeamInfoByIdVo>(baseUrl + "/machine/production/getMachineTeamInfoById", bo);
  },
  /**
   * 获取指定工单生产计数信息-/machine/production/getProducingProductionCountInfoById
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionGetProducingProductionCountInfoById(bo: bo.IPostMachineProductionGetProducingProductionCountInfoByIdBo) {
    return post<vo.IPostMachineProductionGetProducingProductionCountInfoByIdVo>(baseUrl + "/machine/production/getProducingProductionCountInfoById", bo);
  },
  /**
   * 获取指定工单生产信息-/machine/production/getProductionWorkOrderById
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionGetProductionWorkOrderById(bo: bo.IPostMachineProductionGetProductionWorkOrderByIdBo) {
    return post<vo.IPostMachineProductionGetProductionWorkOrderByIdVo>(baseUrl + "/machine/production/getProductionWorkOrderById", bo);
  },
  /**
   * 机台屏-工单信息-获取工单配置字段和内容，根据工单id获取-/machine/production/getWorkOrderInfoById
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionGetWorkOrderInfoById(bo: bo.IPostMachineProductionGetWorkOrderInfoByIdBo) {
    return post<page<vo.IPostMachineProductionGetWorkOrderInfoByIdVo>>(baseUrl + "/machine/production/getWorkOrderInfoById", bo);
  },
  /**
   * 获取指定工单工序接点列表-/machine/production/getProcedureOperationListById
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionGetProcedureOperationListById(bo: bo.IPostMachineProductionGetProcedureOperationListByIdBo) {
    return post<vo.IPostMachineProductionGetProcedureOperationListByIdVo>(baseUrl + "/machine/production/getProcedureOperationListById", bo);
  },
  /**
   * 判断机台是否在生产-/machine/production/checkMachineIsProducing
   * @returns 
   */
  postMachineProductionCheckMachineIsProducing() {
    return post<vo.IPostMachineProductionCheckMachineIsProducingVo>(baseUrl + "/machine/production/checkMachineIsProducing");
  },
  /**
   * 机台端获取上机用户绩效总和-/machine/production/getUserPerformanceSum
   * @returns 
   */
  postMachineProductionGetUserPerformanceSum() {
    return post<vo.IPostMachineProductionGetUserPerformanceSumVo>(baseUrl + "/machine/production/getUserPerformanceSum");
  },
  /**
   * 生产前置工单未完成二次确认-/machine/production/secondaryConfirmation
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionSecondaryConfirmation(bo: bo.IPostMachineProductionSecondaryConfirmationBo) {
    return post(baseUrl + "/machine/production/secondaryConfirmation", bo);
  },
  /**
   * 获取设备及当前投料数-/machine/production/getInCount
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionGetInCount(bo: bo.IPostMachineProductionGetInCountBo) {
    return post<vo.IPostMachineProductionGetInCountVo>(baseUrl + "/machine/production/getInCount", bo);
  },
  /**
   * 取消生产工单-/machine/production/cancelWorkOrder
   * @param bo 传入参数
   * @returns 
   */
  postMachineProductionCancelWorkOrder(bo: bo.IPostMachineProductionCancelWorkOrderBo) {
    return post(baseUrl + "/machine/production/cancelWorkOrder", bo);
  },
};