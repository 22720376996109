
import { ref, onMounted, defineComponent, reactive } from "vue";
import API, { IGetMachineTeamGetMachineBindListVo, IGetMachineTeamGetSimpleTeamUserListVo, IPostMachineTeamBindTeamBo } from "@/api/peopleTeam"
import { message } from "ant-design-vue";
import { storage } from "../constant/index";
import { intoMain, getMqttConnectInfor } from "@/constant/index";
import MqttAPI, { IGetMachineProductionGetMqttConnectInfoVo, } from "@/api/machine"
export default defineComponent({
    setup(props, ctx) {
        let activeIndedx: any = ref(storage.getTeamId('teamId') ? JSON.parse(JSON.stringify(storage.getTeamId('teamId'))).teamId : '')
        const LoginUserId = ref(localStorage.getItem("userId"))
        const userId = ref(storage.getTeamId('teamId') ? JSON.parse(JSON.stringify(storage.getTeamId('teamId'))).userId : '')
        let activeStyle: any = ref({
            borderWidth: "1px",
            borderStyle: 'solid',
            borderColor: '#13C2C2',
        })
        const msg = ref<string>('')
        let tableList = ref<IGetMachineTeamGetSimpleTeamUserListVo[]>([])
        let mqttDate = ref<IGetMachineProductionGetMqttConnectInfoVo>(null)

        const parmas = reactive<IPostMachineTeamBindTeamBo>({
            teamId: null,
            machineId: null
        })
        const equimentParmas = ref<IGetMachineTeamGetMachineBindListVo>(storage.getNewMachineId('NewmachineId'))
        const clickData = ref(storage.getTeamId('teamId') ? JSON.parse(JSON.stringify(storage.getTeamId('teamId'))) : {})
        // ---------------------方法定义-------------------------
        const getlist = () => {
            API.getMachineTeamGetSimpleTeamUserList({ machineId: equimentParmas.value.id }).then(res => {
                tableList.value = res.list
                if (storage.getTeamId('teamId') && JSON.parse(JSON.stringify(storage.getTeamId('teamId'))).teamId) {
                    res.list.map(item => {
                        if (item.teamId == JSON.parse(JSON.stringify(storage.getTeamId('teamId'))).teamId) {
                            parmas.teamId = JSON.parse(JSON.stringify(storage.getTeamId('teamId'))).teamId
                        }
                    })
                }
            })
        }
        const chooseTeam = function (item: any) {
            if (!userId.value) {
                userId.value = LoginUserId.value
            }
            activeIndedx.value = item.teamId
            clickData.value = item
            parmas.teamId = item.teamId
        };
        //返回上一步
        const back = () => {
            ctx.emit('backClick', true)
        }
        //主页切换班组取消
        const cancel = () => {
            intoMain()
        }
        //确定进入主页面
        const sure = () => {
            parmas.machineId = equimentParmas.value.id
            API.postMachineTeamBindTeam(parmas).then((res) => {
                clickData.value['userId'] = localStorage.getItem('userId')
                equimentParmas.value['userId'] = localStorage.getItem('userId')
                storage.set('teamId', clickData.value)
                storage.set('machineId', equimentParmas.value)
                localStorage.removeItem('pageName');
                message.success({ content: "机台和班组绑定成功", });
                getMqttConnectInfor()
                intoMain()
            })

        }
        onMounted(() => {
            //生命周期处理内容
            getlist()
        });
        return {
            activeIndedx,
            activeStyle,
            equimentParmas,
            msg,
            parmas,
            LoginUserId,
            userId,
            chooseTeam,
            tableList,
            getlist,
            back,
            sure,
            cancel
        }
    }
})
