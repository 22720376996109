import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea08f0f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-center" }
const _hoisted_2 = { class: "title flex justify-between items-center" }
const _hoisted_3 = { class: "flex-1 flex justify-between items-center" }
const _hoisted_4 = { class: "tooltipText" }
const _hoisted_5 = { class: "explain" }
const _hoisted_6 = {
  key: 0,
  class: "title-tips"
}
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "text-center value" }
const _hoisted_9 = {
  key: 0,
  class: "preProcedureNum"
}
const _hoisted_10 = { class: "center" }
const _hoisted_11 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_circle_outlined = _resolveComponent("info-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_alertTips = _resolveComponent("alertTips")!
  const _component_minus_outlined = _resolveComponent("minus-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.name), 1),
          _createVNode(_component_a_tooltip, {
            placement: "top",
            trigger: "click"
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.name == '材废数' ? '指投料数中发现的材料废品数，属于上道工序中未检出的废品' : '指生产过程中产生的工序废品数'), 1)
            ]),
            default: _withCtx(() => [
              (_ctx.iconShow)
                ? (_openBlock(), _createBlock(_component_info_circle_outlined, {
                    key: 0,
                    class: "tooltipIcon"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.explain), 1)
      ]),
      (_ctx.tipsShow && _ctx.tipsValue != 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_alertTips, {
              value: _ctx.tipsValue || 0
            }, null, 8, ["value"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createTextVNode(_toDisplayString(_ctx.showValue) + " ", 1),
        (_ctx.preProcedureOutQty)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, "/" + _toDisplayString(_ctx.preProcedureOutQty), 1))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.disable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "flex flex-1 items-center justify-end",
            style: _normalizeStyle({ height: _ctx.$px2rem(' 81px') })
          }, [
            _createElementVNode("div", {
              class: "icon-button",
              onTouchstart: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goTouch($event, 'reduce')), ["prevent"])),
              onTouchmove: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Touchmove && _ctx.Touchmove(...args)), ["prevent"])),
              onTouchend: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.outTouch($event, 'reduce')), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_minus_outlined, { class: "icon" })
              ])
            ], 32),
            _createElementVNode("div", {
              class: "icon-button increase",
              onTouchstart: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.goTouch($event, 'increase')), ["prevent"])),
              onTouchmove: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Touchmove && _ctx.Touchmove(...args)), ["prevent"])),
              onTouchend: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.outTouch($event, 'increase')), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_plus_outlined, { class: "icon" })
              ])
            ], 32)
          ], 4))
        : _createCommentVNode("", true)
    ])
  ]))
}