
import { ref, onMounted, reactive, defineComponent } from "vue";
import API, { IGetMachineTeamGetMachineBindListVo, IPostMachineTeamBindMachineBo } from "@/api/peopleTeam"
import router from "@/router/index";
import { storage } from "../constant/index"
import { logout, intoMain } from "@/constant/index";
//---------------------传入参数、回调、监听定义---------------------
export default defineComponent({
    setup(props, ctx) {
        const createCreative = ref(null);
        let activeIndedx: any = ref(storage.getMachineId('machineId') ? JSON.parse(JSON.stringify(storage.getMachineId('machineId'))).id : '')
        const clickObj = ref<IGetMachineTeamGetMachineBindListVo>(storage.getMachineId('machineId') ? storage.getMachineId('machineId') : null)
        const LoginUserId = ref(localStorage.getItem("userId"))
        const userId = ref(storage.getTeamId('machineId') ? JSON.parse(JSON.stringify(storage.getTeamId('machineId'))).userId : '')
        const visible = ref<boolean>(false);
        const msg = ref<string>('')
        let activeStyle: any = ref({
            borderWidth: "1px",
            borderStyle: 'solid',
            borderColor: '#13C2C2',
        })
        let parmas = reactive<IPostMachineTeamBindMachineBo>({
            machineId: undefined
        })
        let listData = ref<IGetMachineTeamGetMachineBindListVo[]>([])
        const restActive = ref({
            background: ' #ED7B2F'
        })
        const freeActive = ref({
            background: '#5E5E5E'
        })
        // ---------------------方法定义-------------------------
        const chooseEquipment = function (item) {
            if (!userId.value) {
                LoginUserId.value = userId.value
            }
            clickObj.value = item
            //选择生产中的机台弹出框确认
            if (item.state == 'producing') {
                visible.value = true;
                return
            }
            activeIndedx.value = item.id
            parmas.machineId = item.id
        };
        //弹框取消
        const modelCancel = () => {
            visible.value = false;
            //回显上次选择的机台
            activeIndedx.value = JSON.parse(JSON.stringify(storage.getMachineId('machineId'))).id || ''
            clickObj.value = JSON.parse(JSON.stringify(storage.getMachineId('machineId'))) || ''
        }
        //弹框二次确认
        const modelSure = () => {
            visible.value = false;
            activeIndedx.value = clickObj.value.id
            parmas.machineId = clickObj.value.id
        }
        //取消设备绑定
        const cancel = () => {
            if (router.currentRoute.value.query && router.currentRoute.value.query.key) {
                intoMain()
            } else {
                logout()
            }
        }
        //进入班组绑定
        const next = () => {
            storage.set('NewmachineId', clickObj.value)
            ctx.emit('chooseClick', true)
        }
        const getList = () => {
            API.getMachineTeamGetMachineBindList().then((res) => {
                listData.value = res.list
                if (storage.getMachineId('machineId') && JSON.parse(JSON.stringify(storage.getMachineId('machineId'))).id) {
                    res.list.map(item => {
                        if (item.id == JSON.parse(JSON.stringify(storage.getMachineId('machineId'))).id) {
                            parmas.machineId = JSON.parse(JSON.stringify(storage.getMachineId('machineId'))).id
                        }
                    })
                }

            })
        }
        onMounted(() => {
            getList()
        })
        return {
            listData,
            activeIndedx,
            activeStyle,
            LoginUserId,
            userId,
            parmas,
            restActive,
            freeActive,
            visible,
            createCreative,
            clickObj,
            msg,
            chooseEquipment,
            cancel,
            next,
            modelCancel,
            modelSure
        }
    },
})


